'use strict'

# $(()->
  # フッター高さ取得
footerHeight =
  ()->
    add = 0
    height = $('#footer').height()
    outerHeight = $('#footer').outerHeight(true)
    diff = height - outerHeight
    if diff > 0
      $('#content').css('padding-bottom',height+add)
      $('#footer').css('height',height)
    else
      $('#content').css('padding-bottom',outerHeight+add)
      $('#footer').css('height',height)
    return

footerHeight()

$(window).on('resize',
  ->
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(
      ()->
        $('#footer').removeAttr('style')
    , 250)
)

$(window).on('load resize',
  ->
    footerHeight()
)
